<template>
    <Layout :style="'min-height:' + innerHeight + 'px'">
        <Header>
            <div class="title_nav_box">
                <div style="position: relative;width: 110px;height: 64px;">
                    <div style="position:absolute;left: -10px;top: 6px;height: 64px;">
                        Better喜雅齿
                        <!-- <img style="height:54px" src="../../assets/page_left.png" alt=""></img> -->
                    </div>
                </div>
                <Menu ref="menuList" mode="horizontal"  :active-name="menuType" @on-select='goChooseType' class="center_nav">
                    <MenuItem name="newhomepage" style="font-size: 16px;">
                            首页
                    </MenuItem>
                    <MenuItem name="homepage" style="font-size: 16px;">
                            会员
                    </MenuItem>
                    <MenuItem name="package" style="font-size: 16px;">
                            套餐
                    </MenuItem>
                    <MenuItem name="order" style="font-size: 16px;">
                        订单
                    </MenuItem>
                    <MenuItem name="withdrawal" style="font-size: 16px;">
                        提现
                    </MenuItem>
                    <MenuItem name="plugin" style="font-size: 16px;">
                        核销
                    </MenuItem>
                    <MenuItem name="banner" style="font-size: 16px;">
                        banner
                    </MenuItem>
                </Menu>
                
                <div class="right_box">                   
                    <div class="layout-nav ml-20">
                        <MenuItem name="logout" style="display: flex;align-items: center;height: 60px;">
                            <div class="bg-circle"><img style="height:40px;border-radius:40px" src="../../assets/tang/head1.png" alt=""></img></div>
                        <!-- <div class="bg-circle">{{ userInfo.account ? userInfo.account.substring(0, 1) : '' }}</div><span
                            style="color:#000;">{{ userInfo.account }}</span> -->
                        </MenuItem>
                        <div class="show-menu">                            
                            <div @click="goChangeAccount">账户管理</div>
                            <div @click="goChangePassword">修改密码</div>
                            <div @click="logout">退出登录 </div>
                        </div>
                    </div>
                </div>
            </div>
        </Header>
        <Layout style="z-index:1">
            <Sider hide-trigger :style="{ background: '#fff' }" v-show="menuType != 'newhomepage' && menuType !='system'">
                <Menu :active-name="String($route.name) " ref="menu" theme="light" width="auto" @on-select ='chooseMenu'  >
                    <div>                       
                        <div v-for="(item, index) in newMenuList" :key="index">
                            <div v-if="item.upMenuName == menuType && ( item.upMenuName == 'product' ? hasAppList.indexOf(item.id)!= -1: true )">
                                <div >
                                    <MenuItem :name="item.unique_auth" :to="item.src" style="display: flex;align-items: center">
                                        <Icon :type="item.icon" size="22"/>
                                        <div style="min-width:56px">{{ item.title }} </div>
                                    </MenuItem>
                                </div>
                            </div>
                        </div>
                    </div>                   
                </Menu>
            </Sider>
            <!-- :style="{ background: '#fff' }" -->
            <Layout >
                <Content :style="{minHeight: '260px'}">
                    <div style="height:  calc(100vh - 64px); overflow: auto;padding: 20px">
                        <router-view></router-view>
                    </div>
                    <!-- <router-view ref="pageComponent"></router-view> -->
                </Content>
            </Layout>
        </Layout>
        <div v-if='isPolling' ><loadings v-if="isGlobalSpin" ></loadings></div>
    </Layout>
</template>

<script>
import { removeCookies } from '@/utils/helper'
import loadings from '@/components/loadings/loadings'
// import { get_system_list } from "@/api/index";
export default {
    metaInfo: {
        title:'首页',
        meta: [
            { name: 'viewport', content: 'width=device-width,user-scalable=yes,initial-scale=0.3,minimum-scale=0.3,maximum-scale=2' }
        ]
    },
    components:{
        loadings
    },
    data() {
        return {
            innerHeight: 0,
            searchContent: '',
            activeName:'newhomepage',
            menuType:'newhomepage',            
            routeName: '',   
            menuList:[],
            hasAppList: [],
            newMenuList:[               
                {
                    children: [],
                    icon: "ios-list",
                    id: 15,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin",
                    upMenuName:'homepage',
                    title: "会员列表",
                    unique_auth: "homepageplatform"
                },
                     
                {
                    children: [],
                    icon: "ios-chatboxes-outline",
                    id: 13,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/memberpackage",
                    upMenuName:'package',
                    title: "套餐",
                    unique_auth: "memberpackage"
                },  
                {
                    children: [],
                    icon: "ios-ionitron-outline",
                    id: 13,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/cardmanagement",
                    upMenuName:'package',
                    title: "卡卷管理",
                    unique_auth: "cardmanagement"
                },  
                {
                    children: [],
                    icon: "ios-keypad-outline",
                    id: 1,
                    is_menu: 1,                    
                    pid: 0,
                    upMenuName:'order',
                    src: "/admin/order",
                    title: "今日订单",
                    unique_auth: "order"
                },   
                {
                    children: [],
                    icon: "ios-create-outline",
                    id: 1,
                    is_menu: 1,                    
                    pid: 0,
                    upMenuName:'order',
                    src: "/admin/orderhistory",
                    title: "历史订单",
                    unique_auth: "orderhistory"
                },
                {
                    children: [],
                    icon: "ios-keypad-outline",
                    id: 1,
                    is_menu: 1,                    
                    pid: 0,
                    upMenuName:'plugin',
                    src: "/admin/writeoff",
                    title: "核销",
                    unique_auth: "writeoff"
                },   
                {
                    children: [],
                    icon: "ios-create-outline",
                    id: 1,
                    is_menu: 1,                    
                    pid: 0,
                    upMenuName:'plugin',
                    src: "/admin/writeoffhistory",
                    title: "历史核销",
                    unique_auth: "writeoffhistory"
                },   
                {
                    children: [],
                    icon: "ios-keypad-outline",
                    id: 1,
                    is_menu: 1,                    
                    pid: 0,
                    upMenuName:'banner',
                    src: "/admin/banner",
                    title: "banner",
                    unique_auth: "banner"
                },   
                             
                {
                    children: [],
                    icon: "ios-ionitron-outline",
                    id: 13,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/moneyexamine",
                    upMenuName:'withdrawal',
                    title: "提现审核",
                    unique_auth: "moneyexamine"
                },              
                {
                    children: [],
                    icon: "ios-create-outline",
                    id: 13,
                    is_menu: 1,
                    pid: 0,
                    src: "/admin/historyexamine",
                    upMenuName:'withdrawal',
                    title: "提现历史",
                    unique_auth: "historyexamine"
                }

            ],           
        }
    },
    watch: {
        '$route.name': function(newVal, oldVal) {   
            if(this.$route.name=='productcontract' || this.$route.name == 'productrestaurant' || this.$route.name == 'productseagullcloud'){                
                this.menuType = 'product'                  
                this.$nextTick(()=>{
                    this.$refs.menu.updateOpened();
                    this.$refs.menu.updateActiveName();
                })
                this.$forceUpdate()
            }
        }
    },
    computed: {       
        userInfo() {
            return this.$store.state.userInfo
        },
        auth() {
            return this.$store.state.auth
        },
        isPolling(){
            return this.$store.state.isPolling
        },
        isGlobalSpin(){
            return this.$store.state.isGlobalSpin
        },
    },
    created() {     
        // this.get_system_list()       
        if(localStorage.getItem("menuType")){
            this.menuType = localStorage.getItem("menuType")          
        }else{
            this.menuType = 'newhomepage'
        }        
        this.innerHeight = window.innerHeight - 2;    
        // 定义一个变量保存上一次记录的 innerHeight 值
        let lastInnerHeight = window.innerHeight;
        // 监听 resize 事件
        window.addEventListener('resize', () => {
            // 获取当前 innerHeight 值
            const currentInnerHeight = window.innerHeight;        
            // 如果 innerHeight 值发生改变，则执行相应的操作
            if (currentInnerHeight !== lastInnerHeight) {
                // 执行你想要的方法
                this.innerHeight = window.innerHeight - 2;                   
                // 更新 lastInnerHeight 变量的值
                lastInnerHeight = currentInnerHeight;
            }
        });          
    },
    mounted(){
        this.innerHeight = window.innerHeight - 2;
    },
    methods: {
        goChangePassword(){           
            this.menuType = 'system'                  
            this.$router.push({ path: '/admin/changepassword' })
        },
        goChangeAccount(){
            this.menuType = 'system'   
            this.$router.push({ path: '/admin/homepageaccount' })
        },
        otherMethod(e){
            console.log('调取方法',e)
        },
        chooseMenu(e){
            // console.log('e',e)
        },
        get_system_list(){
            this.hasAppList=[]
            get_system_list().then(res=>{
                res.data.forEach(item=>{
                    this.hasAppList.push(item.id)
                })            
                this.$nextTick(()=>{
                    this.$refs.menu.updateOpened();
                    this.$refs.menu.updateActiveName();
                })
            })           
        },
        logout() {
            localStorage.clear()
            removeCookies('teeth-token')
            this.$router.push({ path: '/' })
        },
        goChooseType(e){
            this.menuType = e  
            console.log('menuType',this.menuType)          
            localStorage.setItem("menuType",e)           
            if(this.menuType == 'homepage' ){
                if(this.$route.name == 'homepageplatform'){
                    return
                }
                this.$router.push({ path: '/admin' })
                this.$forceUpdate()
            }else if (this.menuType == 'order') {
                if(this.$route.name == 'order'){
                    return
                }
                this.$router.push({ path: '/admin/order' })
                this.$forceUpdate()               
            }else if (this.menuType == 'package') {
                this.$router.push({ path: '/admin/memberpackage' })
                this.$forceUpdate()              
            }else if (this.menuType == 'withdrawal') {
                this.$router.push({ path: '/admin/moneyexamine' })
                this.$forceUpdate()              
            }else if(this.menuType == 'newhomepage'){
                this.$router.push({ path: '/admin/newhomepage' })
            } else if(this.menuType == 'banner'){                
                this.$router.push({ path: '/admin/banner' })
                this.$forceUpdate()   
            } else {
                if(this.$route.name == 'writeoff'){
                    return
                }
                this.$router.push({ path: '/admin/writeoff' })
                this.$forceUpdate()
            }
            //菜单更新  如果无  则无选中效果
            this.$nextTick(()=>{
                this.$refs.menu.updateOpened();
                this.$refs.menu.updateActiveName();
            })
        },
        inputchange() {
            this.$store.commit('setSearch', this.searchContent)
        },
    }
}
</script>

<style scoped lang="scss">
.ivu-menu-light.ivu-menu-horizontal .ivu-menu-item{
    color:#515a6e;
    border-bottom: unset;

}
/deep/ .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item:hover, .ivu-menu-light.ivu-menu-horizontal .ivu-menu-item-active,  .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu-active, .ivu-menu-light.ivu-menu-horizontal .ivu-menu-submenu:hover {
    color:#4877E8;
    border-bottom:2px solid #4877E8;
}
/deep/ .ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) i{
    color: #fff!important;;
}
/deep/ .ivu-menu-horizontal.ivu-menu-light:after {
    height: 0px;
}

/deep/.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu){
    /* color:#fff!imporant; */
    color: #fff !important;
}
/deep/ .ivu-checkbox-checked .ivu-checkbox-inner {
    border-color: #4877E8 !important;
    background-color: #4877E8 !important;
}

.layout {
    border: 1px solid #d7dde4;
    background: #f5f7f9;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}

.layout-logo {
    width: 100px;
    height: 30px;
    background: #5b6270;
    border-radius: 3px;
    float: left;
    position: relative;
    top: 15px;
    left: 20px;
}
.layout-nav {
    /* width: 420px; */
    margin: 0 auto;
    margin-right: 20px;
}
.ivu-layout-header {
    background: #FFFFFF;
    box-shadow: #F3EEE2 0px 3px 8px;    
    z-index: 9;
}
/deep/.ivu-menu-item {
    margin: 0 32px;
}
.ivu-layout-sider {
    box-shadow: #F3EEE2 0px 1px 4px;
}

.ivu-layout-content {
    border-radius: 8px;
}

.ivu-menu-vertical.ivu-menu-light:after {
    background: none;
}

.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) {
    color: #000;
    background: #4877E8;
    justify-content: center;
}

.ivu-menu-vertical .ivu-menu-item,
.ivu-menu-vertical .ivu-menu-submenu-title {
    color: #000;
    justify-content: center;
}

.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu) i {
    color: #0893FF;
}

.ivu-menu-light.ivu-menu-vertical .ivu-menu-item-active:not(.ivu-menu-submenu):after {
    background: unset;
}

.ivu-menu-light {
    margin: 20px 0;
}

.title_nav_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.right_box {
    display: flex;
    align-items: center;
}
.layout-nav {
    /* width: 200px; */
    margin: 0 auto;
    margin-right: 20px;
    position: relative;
}

.layout-nav:hover .show-menu {
    display: inline-block;
    right: 0px;
}

.bg-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #4877E8;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.center_nav {
    position: absolute; /* 让子元素脱离文档流 */
    left: 25%; /* 调整子元素左边距为父元素宽度的一半 */
    transform: translateX(-25%); /* 再往左移动子元素自身宽度的一半，实现居中对齐 */
}
.show-menu {
    display: none;
    position: absolute;
    width: 100px;
    /* right: 30px; */
    top: 60px;
    background-color: #fff;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 #ebeef5;
    text-align: center;
}

.show-menu div {
    cursor: pointer;
}

.show-menu div:hover {
    background: #d7dde4;
}

.ml-5 {
    margin-left: 5px;
}

.ml-20 {
    margin-left: 20px;
}
.ivu-menu-vertical .ivu-menu-item:hover, .ivu-menu-vertical .ivu-menu-submenu-title:hover{
    color:#000;
}
</style>