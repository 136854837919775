import Vue from 'vue'
import App from './App.vue'
import config from '@/config';
import router from './router'
import store from './store'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import Meta from 'vue-meta'
// 导入富文本编译器
import VueQuillEditor from 'vue-quill-editor'
// require styles 导入富文本编译器对应的样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css' 
 
// 将富文本编辑器注册为全局可用的组件
Vue.use(VueQuillEditor) 

Vue.config.productionTip = false
Vue.config.productionTip = false
Vue.prototype.config = config
Vue.use(ViewUI);
Vue.use(Meta)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
