/** 表单设置路由 */
export default {
    name: 'newhomepage',
    path: 'newhomepage',
    meta: {
        title: '首页'
    },
    component: ()=>import('@/pages/newhomepage/newhomepage.vue'),
    children: []
}
