import Vue from 'vue'
import VueRouter from 'vue-router'
import mainLayout from "@/components/mainLayout"
import homepageplatform from "./modules/homepageplatform"

import newhomepage from "./modules/newhomepage" //首页
import memberpackage from "./modules/memberpackage" //套餐
import cardmanagement from "./modules/cardmanagement" //卡卷列表
import changepassword from "./modules/changepassword" //修改密码
import homepageaccount from "./modules/homepageaccount"//账户
import writeoff from "./modules/writeoff"//核销
import banner from "./modules/banner"//核销

import writeoffhistory from "./modules/writeoffhistory"//历史核销
import order from "./modules/order"//核销
import orderhistory from "./modules/orderhistory"//历史核销
import moneyexamine from "./modules/moneyexamine"//提现审核
import historyexamine from "./modules/historyexamine"//提现审核


// independentDomain
import ViewUI from 'view-design';
Vue.use(ViewUI);
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'hash',
    routes: [
        {
            name: '登录',
            path: '/',
            component: () => import("@/pages/login/login.vue")
        },
        {
            name: '登录跳转',
            path: '/jump',
            component: () => import("@/pages/login/jump.vue")
        },
        {
            path: '/admin',
            meta: { title: '喜雅齿' },
            component: mainLayout,
            children: [
                ...homepageplatform,    //会员列表            
                newhomepage,                
                memberpackage,//套餐
                cardmanagement,//卡卷管理
                writeoff, //核销
                writeoffhistory,//历史核销
                homepageaccount,//账户管理       
                banner,//banner     
                changepassword,  //修改密码
                order,
                orderhistory,
                moneyexamine,
                historyexamine
            ]
        },
    ]
})
// const router = new VueRouter({
//     mode: 'history',
//     base: process.env.BASE_URL,
//     routes
// })
router.beforeEach((to, from, next) => {
    ViewUI.LoadingBar.start();
    next();
});


router.beforeEach((to, from, next) => {
    window.document.title = "喜雅齿";    
    ViewUI.LoadingBar.finish();
    next();
})
export default router
